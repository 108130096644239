import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Attribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SecurityContext,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AngularEditorConfig, angularEditorConfig } from '../config';
import { CustomAngularEditorToolbarComponent } from '../custom-angular-editor-toolbar/custom-angular-editor-toolbar.component';
import { CustomAngularEditorService } from '../services/custom-angular-editor.service';
import { isDefined } from '../utils';

@Component({
  selector: 'custom-angular-editor',
  templateUrl: './custom-angular-editor.component.html',
  styleUrls: ['./custom-angular-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomAngularEditorComponent),
      multi: true
    },
    CustomAngularEditorService
  ]
})
export class CustomAngularEditorComponent
  implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy
{
  private onChange: (value: string) => void;
  private onTouched: () => void;

  modeVisual = true;
  showPlaceholder = false;
  disabled = false;
  focused = false;
  touched = false;
  changed = false;

  focusInstance;
  blurInstance;
  mentionSearchText = '';
  mentionPosition = null;

  @Input() componentName = '';
  @Input() id = '';
  @Input() config: AngularEditorConfig = angularEditorConfig;
  @Input() placeholder = '';
  @Input() tabIndex: number | null;
  @Input() isEditable = true;
  @Input() fullHeight = false;
  @Input() isShowPasteBtn = true;
  @Input() isMentionsEnabled = false;
  @Output() html;
  @ViewChild('highlight_editior', { static: false })
  highlightTextArea: ElementRef;
  @ViewChild('editor', { static: true }) textArea: ElementRef;
  @ViewChild('editorWrapper', { static: true }) editorWrapper: ElementRef;
  @ViewChild('editorToolbar')
  editorToolbar: CustomAngularEditorToolbarComponent;
  @Output() viewMode = new EventEmitter<boolean>();

  /** emits `blur` event when focused out from the textarea */
  // eslint-disable-next-line @angular-eslint/no-output-native, @angular-eslint/no-output-rename
  @Output('blur') blurEvent: EventEmitter<FocusEvent> =
    new EventEmitter<FocusEvent>();

  /** emits `focus` event when focused in to the textarea */
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-native
  @Output('focus') focusEvent: EventEmitter<FocusEvent> =
    new EventEmitter<FocusEvent>();

  @HostBinding('attr.tabindex') tabindex = -1;

  @HostListener('focus')
  onFocus() {
    this.focus();
  }

  @HostListener('paste', ['$event'])
  async pasteFromClipboard(event): Promise<void> {
    event.preventDefault();
    const text =
      event.clipboardData.getData('text/html') ||
      event.clipboardData.getData('text/plain');
    if (text) {
      const sanitizedHtml = await this.removeAttributesAndLinks(
        this.sanitizer.sanitize(SecurityContext.HTML, text)
      );
      this.editorService.insertHtml(`<div>${sanitizedHtml}</div>`);
    }
  }

  constructor(
    private r: Renderer2,
    private editorService: CustomAngularEditorService,
    @Inject(DOCUMENT) private doc,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    @Attribute('tabindex') defaultTabIndex: string,
    @Attribute('autofocus') private autoFocus
  ) {
    const parsedTabIndex = Number(defaultTabIndex);
    this.tabIndex =
      parsedTabIndex || parsedTabIndex === 0 ? parsedTabIndex : null;
  }

  ngOnInit() {
    this.config.toolbarPosition = this.config.toolbarPosition
      ? this.config.toolbarPosition
      : angularEditorConfig.toolbarPosition;
  }

  ngAfterViewInit() {
    if (isDefined(this.autoFocus)) {
      this.focus();
    }
  }

  /**
   * Executed command from editor header buttons
   * @param command string from triggerCommand
   */
  executeCommand(command: string) {
    this.focus();
    if (command === 'focus') {
      return;
    }
    if (command === 'toggleEditorMode') {
      this.toggleEditorMode(this.modeVisual);
    } else if (command !== '') {
      if (command === 'clear') {
        this.editorService.removeSelectedElements(this.getCustomTags());
        this.onContentChange(this.textArea.nativeElement);
      } else if (command === 'default') {
        this.editorService.removeSelectedElements('h1,h2,h3,h4,h5,h6,p,pre');
        this.onContentChange(this.textArea.nativeElement);
      } else {
        this.editorService.executeCommand(command);
      }
      this.exec();
    }
  }

  /**
   * focus event
   */
  onTextAreaFocus(event: FocusEvent): void {
    if (this.focused) {
      event.stopPropagation();
      return;
    }
    this.focused = true;
    this.focusEvent.emit(event);
    if (!this.touched || !this.changed) {
      this.editorService.executeInNextQueueIteration(() => {
        this.configure();
        this.touched = true;
      });
    }

    this.editorService.saveCursorPos();
  }

  /**
   * @description fires when cursor leaves textarea
   */
  public onTextAreaMouseOut(): void {
    this.editorService.saveSelection();
  }

  /**
   * blur event
   */
  onTextAreaBlur(event: FocusEvent) {
    /**
     * save selection if focussed out
     */
    this.editorService.executeInNextQueueIteration(
      this.editorService.saveSelection
    );

    if (typeof this.onTouched === 'function') {
      this.onTouched();
    }

    if (event.relatedTarget !== null) {
      const parent = (event.relatedTarget as HTMLElement).parentElement;
      if (
        !parent.classList.contains('angular-editor-toolbar-set') &&
        !parent.classList.contains('ae-picker')
      ) {
        this.blurEvent.emit(event);
        this.focused = false;
      }
    }
  }

  /**
   *  focus the text area when the editor is focused
   */
  focus() {
    if (this.modeVisual) {
      this.textArea.nativeElement.focus();
    } else {
      const sourceText = this.doc.getElementById('sourceText' + this.id);
      sourceText.focus();
      this.focused = true;
    }
    this.editorService.saveCursorPos();
  }

  /**
   * Executed from the contenteditable section while the input property changes
   * @param element html element from contenteditable
   */
  onContentChange(element: HTMLElement): void {
    let html = '';
    if (this.modeVisual) {
      html = element.innerHTML;
    } else {
      html = element.innerText;
    }
    if (!html || html === '<br>') {
      html = '';
    }
    if (typeof this.onChange === 'function') {
      this.onChange(
        this.config.sanitize || this.config.sanitize === undefined
          ? this.sanitizer.sanitize(SecurityContext.HTML, html)
          : html
      );
      if (!html !== this.showPlaceholder) {
        this.togglePlaceholder(this.showPlaceholder);
      }
    }
    this.changed = true;
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   *
   * @param fn a function
   */
  registerOnChange(fn): void {
    this.onChange = (e) => (e === '<br>' ? fn('') : fn(e));
  }

  /**
   * Set the function to be called
   * when the control receives a touch event.
   *
   * @param fn a function
   */
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  /**
   * Write a new value to the element.
   *
   * @param value value to be executed when there is a change in contenteditable
   */
  writeValue(value): void {
    if ((!value || value === '<br>' || value === '') !== this.showPlaceholder) {
      this.togglePlaceholder(this.showPlaceholder);
    }

    if (value === undefined || value === '' || value === '<br>') {
      value = null;
    }

    this.refreshView(value);
  }

  /**
   * refresh view/HTML of the editor
   *
   * @param value html string from the editor
   */
  refreshView(value: string): void {
    const normalizedValue = value === null ? '' : value;

    this.r.setProperty(
      this.textArea.nativeElement,
      'innerHTML',
      normalizedValue
    );

    return;
  }

  /**
   * toggles placeholder based on input string
   *
   * @param value A HTML string from the editor
   */
  togglePlaceholder(value: boolean): void {
    if (!value) {
      this.r.addClass(this.editorWrapper.nativeElement, 'show-placeholder');
      this.showPlaceholder = true;
    } else {
      this.r.removeClass(this.editorWrapper.nativeElement, 'show-placeholder');
      this.showPlaceholder = false;
    }
  }

  /**
   * Implements disabled state for this element
   *
   * @param isDisabled Disabled flag
   */
  setDisabledState(isDisabled: boolean): void {
    const div = this.textArea.nativeElement;
    const action = isDisabled ? 'addClass' : 'removeClass';
    this.r[action](div, 'disabled');
    this.disabled = isDisabled;
  }

  /**
   * toggles editor mode based on bToSource bool
   *
   * @param bToSource A boolean value from the editor
   */
  toggleEditorMode(bToSource: boolean) {
    let oContent;
    const editableElement = this.textArea.nativeElement;

    if (bToSource) {
      oContent = this.r.createText(editableElement.innerHTML);
      this.r.setProperty(editableElement, 'innerHTML', '');
      this.r.setProperty(editableElement, 'contentEditable', false);

      const oPre = this.r.createElement('pre');
      this.r.setStyle(oPre, 'margin', '0');
      this.r.setStyle(oPre, 'outline', 'none');

      const oCode = this.r.createElement('code');
      this.r.setProperty(oCode, 'id', 'sourceText' + this.id);
      this.r.setStyle(oCode, 'display', 'block');
      this.r.setStyle(oCode, 'white-space', 'pre-wrap');
      this.r.setStyle(oCode, 'word-break', 'keep-all');
      this.r.setStyle(oCode, 'outline', 'none');
      this.r.setStyle(oCode, 'margin', '0');
      this.r.setStyle(oCode, 'background-color', '#fff5b9');
      this.r.setProperty(oCode, 'contentEditable', true);
      this.r.appendChild(oCode, oContent);
      this.focusInstance = this.r.listen(oCode, 'focus', (event) =>
        this.onTextAreaFocus(event)
      );
      this.blurInstance = this.r.listen(oCode, 'blur', (event) =>
        this.onTextAreaBlur(event)
      );
      this.r.appendChild(oPre, oCode);
      this.r.appendChild(editableElement, oPre);

      // move to service
      this.doc.execCommand('defaultParagraphSeparator', false, 'div');

      this.modeVisual = false;
      this.viewMode.emit(false);
      oCode.focus();
    } else {
      if (this.doc.querySelectorAll) {
        this.r.setProperty(
          editableElement,
          'innerHTML',
          editableElement.innerText
        );
      } else {
        oContent = this.doc.createRange();
        oContent.selectNodeContents(editableElement.firstChild);
        this.r.setProperty(editableElement, 'innerHTML', oContent.toString());
      }
      this.r.setProperty(editableElement, 'contentEditable', true);
      this.modeVisual = true;
      this.viewMode.emit(true);
      this.onContentChange(editableElement);
      editableElement.focus();
    }
    this.editorToolbar.setEditorMode(!this.modeVisual);
  }

  /**
   * toggles editor buttons when cursor moved or positioning
   *
   * Send a node array from the contentEditable of the editor
   */
  exec() {
    setTimeout(() => {
      if (this.editorToolbar) {
        this.editorToolbar.triggerButtons();

        let userSelection;
        if (this.doc.getSelection) {
          userSelection = this.doc.getSelection();
          this.editorService.executeInNextQueueIteration(
            this.editorService.saveSelection
          );
        }

        let a = userSelection.focusNode;
        const els = [];
        while (a && a.id !== 'editor') {
          els.unshift(a);
          a = a.parentNode;
        }
        this.editorToolbar.triggerBlocks(els);
      }
    });
  }

  private configure() {
    this.editorService.uploadUrl = this.config.uploadUrl;
    this.editorService.uploadWithCredentials =
      this.config.uploadWithCredentials;
    if (this.config.defaultParagraphSeparator) {
      this.editorService.setDefaultParagraphSeparator(
        this.config.defaultParagraphSeparator
      );
    }
    if (this.config.defaultFontName) {
      this.editorService.setFontName(this.config.defaultFontName);
    }
    if (this.config.defaultFontSize) {
      this.editorService.setFontSize(this.config.defaultFontSize);
    }
  }

  getFonts() {
    const fonts = this.config.fonts
      ? this.config.fonts
      : angularEditorConfig.fonts;
    return fonts.map((x) => {
      return { label: x.name, value: x.name };
    });
  }

  getCustomTags() {
    const tags = ['span'];
    this.config.customClasses.forEach((x) => {
      if (x.tag !== undefined) {
        if (!tags.includes(x.tag)) {
          tags.push(x.tag);
        }
      }
    });
    return tags.join(',');
  }

  ngOnDestroy() {
    if (this.blurInstance) {
      this.blurInstance();
    }
    if (this.focusInstance) {
      this.focusInstance();
    }
  }

  filterStyles(html: string): string {
    html = html.replace('position: fixed;', '');
    return html;
  }

  public insertEmoji(html: string) {
    this.focus();
    const restored = this.editorService.restoreSelection();
    if (restored) {
      this.editorService.insertHtml(html);
    }
  }

  onKeyup() {
    if (this.isEditable && this.isMentionsEnabled) {
      this.checkForMention();
      this.checkForEditingMention();
    }
  }

  checkForEditingMention() {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    const parentElement = range.startContainer.parentElement;
    if (
      parentElement &&
      parentElement.tagName === 'SPAN' &&
      parentElement.classList.contains('mention')
    ) {
      parentElement.replaceWith('');
      this.doc.execCommand('insertText', false, '');
    }
  }

  getCurrentIndex() {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);

    if (range) {
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(this.textArea.nativeElement);
      preCaretRange.setEnd(range.startContainer, range.startOffset);
      this.mentionPosition = range.startOffset;
      return preCaretRange.toString().length; // Get the length from the start
    } else {
      return -1; // If no range, set to -1
    }
  }

  checkForMention() {
    const cursorIndex = this.getCurrentIndex();
    const atSymbolIndex = this.textArea.nativeElement?.textContent.lastIndexOf(
      '@',
      cursorIndex
    );
    this.mentionPosition = atSymbolIndex;

    if (atSymbolIndex !== -1) {
      const mentionString = this.textArea.nativeElement?.textContent.slice(
        atSymbolIndex + 1,
        cursorIndex > -1 ? cursorIndex : -1
      );
      if (mentionString.includes(' ')) {
        this.mentionPosition = null;
        this.mentionSearchText = '';
        return;
      }
      this.mentionSearchText = mentionString;
    }
  }

  selectedMention(user: any) {
    this.removeCharacterAtCursor();
    const mentionElement =
      (this.textArea.nativeElement?.textContent.length > 0 ? '&nbsp;' : '') +
      `<span data-userid="${user.id}" data-email="${user.email}" class="mention">&#64;${user.name}</span>&nbsp;`;
    this.editorService.insertHtml(mentionElement);
    this.mentionPosition = null;
    this.mentionSearchText = '';
  }

  removeCharacterAtCursor(): void {
    const selection = window.getSelection();

    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);

    // Get the current text node where the cursor is
    let node = range.startContainer;
    this.mentionPosition = range.startOffset
      ? range.startOffset
      : this.mentionPosition;

    // If the node is not a text node, find the last text node
    if (node.nodeType !== Node.TEXT_NODE) {
      node = this.getLastTextNode(node);
    }

    // Get the text content and check if the character at the cursor position matches
    const textContent = node.textContent;

    // Check if the cursor is at a valid position and if the character matches
    const atSymbolIndex = textContent.lastIndexOf('@', this.mentionPosition);
    if (
      textContent.includes('@' + this.mentionSearchText) &&
      this.mentionPosition > 0 &&
      atSymbolIndex > -1 &&
      textContent.substring(
        atSymbolIndex,
        atSymbolIndex + this.mentionSearchText.length + 1
      ) ===
        '@' + this.mentionSearchText
    ) {
      // Create a new text content without the specific character
      const newTextContent =
        textContent.substring(0, atSymbolIndex) +
        textContent.substring(
          atSymbolIndex + this.mentionSearchText.length + 1
        );
      // Replace the old text with the new one
      node.textContent = newTextContent; // Update with new text

      // Restore the cursor position (move it back one character)
      const newRange = document.createRange();
      newRange.setStart(node, atSymbolIndex);
      newRange.collapse(true);

      // Clear existing selection
      selection.removeAllRanges();
      selection.addRange(newRange);
    }
  }

  // Helper function to get the last text node in a given node
  private getLastTextNode(node: Node): Node {
    if (node.nodeType === Node.TEXT_NODE) {
      return node;
    }

    const childNodes = node.childNodes;
    if (childNodes.length === 0) return null;

    return this.getLastTextNode(childNodes[childNodes.length - 1]);
  }

  private async removeAttributesAndLinks(html: string): Promise<SafeHtml> {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    await this.traverseAndSanitize(tempDiv);

    return this.sanitizer.sanitize(SecurityContext.HTML, tempDiv.innerHTML);
  }

  private traverseAndSanitize(element: HTMLElement): Promise<void> {
    return new Promise((resolve) => {
      // Remove style and class attributes from the element and its children
      Array.from(element.children).forEach((child) => {
        if (child instanceof HTMLElement) {
          child.removeAttribute('style'); // Remove style attribute
          child.removeAttribute('class'); // Remove class attribute

          // Recursively apply to child elements
          this.traverseAndSanitize(child);
        }
      });
      // Remove all <img> tags
      const images = element.getElementsByTagName('img');
      while (images.length > 0) {
        images[0].parentNode?.removeChild(images[0]);
      }

      // Resolve promise once sanitization is complete
      resolve();
    });
  }
}
