import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeIconModule } from '../shared-module/font-awesome-icon.module';
import { AeSelectComponent } from './ae-select/ae-select.component';
import { CustomAngularEditorToolbarComponent } from './custom-angular-editor-toolbar/custom-angular-editor-toolbar.component';
import { CustomAngularEditorComponent } from './custom-angular-editor/custom-angular-editor.component';
import { CustomAngularEditorService } from './services/custom-angular-editor.service';
import { ContactListComponent } from './contact-list/contact-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    CustomAngularEditorComponent,
    CustomAngularEditorToolbarComponent,
    AeSelectComponent,
    ContactListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeIconModule,
    InfiniteScrollModule
  ],
  exports: [CustomAngularEditorComponent],
  providers: [CustomAngularEditorService]
})
export class CustomAngularEditorModule {}
export { AngularEditorConfig } from './config';
