<header class="revamp-header main-header">
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-4 offset-4 offset-lg-0 d-sm-flex justify-content-sm-center justify-content-lg-start align-self-center"
      >
        <a (click)="goToProjects()">
          <img
            class="revamp-logo"
            src="assets/images/new-revamp-logo.svg"
            alt="Builder360"
            title="Builder360"
            width="125"
            height="24"
          />
        </a>
      </div>
      <div class="col-lg-8 col-md-4 col-sm-4 col-4">
        <div
          *ngIf="checkUserLogged()"
          class="head-right profile-hide profile-show"
        >
          <div
            class="cockpit-detail-wrap"
            *ngIf="commonService.isShowFeatureDifference"
          >
            <div class="feature-diff-wrap">
              <div class="feature-info">
                <span class="title">Feature difference</span>
                <span
                  *ngIf="isShowFeatureDifference(featureDifferenceDetails)"
                  class="feature-chip {{
                    getFeaturePercentage() < 50
                      ? 'is-red'
                      : getFeaturePercentage() >= 50 &&
                          getFeaturePercentage() < 75
                        ? 'is-yellow'
                        : getFeaturePercentage() >= 75
                          ? 'is-green'
                          : ''
                  }}"
                  >{{
                    getFeaturePercentage() ? getFeaturePercentage() : 0
                  }}%</span
                >
                <span
                  class="feature-chip"
                  *ngIf="!isShowFeatureDifference(featureDifferenceDetails)"
                >
                  NA</span
                >
                <span
                  class="feature-chip progress-chip"
                  *ngIf="isShowFeatureDifference(featureDifferenceDetails)"
                  >{{ featureDifferenceDetails?.feature_in_project_count }}/{{
                    featureDifferenceDetails?.total_features_count
                  }}</span
                >
              </div>
              <div
                class="feature-info-tooltip"
                *ngIf="isShowFeatureDifference(featureDifferenceDetails)"
              >
                <div class="info-row">
                  <div class="info-desc">
                    Overall buildcard feature difference:
                  </div>
                  <div class="count-data">
                    <span
                      >{{
                        featureDifferenceDetails?.feature_in_project_count
                      }}/{{
                        featureDifferenceDetails?.total_features_count
                      }}</span
                    >
                    <strong
                      >{{
                        getFeaturePercentage() ? getFeaturePercentage() : 0
                      }}%</strong
                    >
                  </div>
                </div>
                <div
                  class="info-row"
                  *ngIf="featureDifferenceDetails?.total_feature_meeting_count"
                >
                  <div class="info-desc">
                    Feature difference as per this meeting:
                  </div>
                  <div class="count-data">
                    <span
                      >{{ featureDifferenceDetails?.features_in_spec_count }}/{{
                        featureDifferenceDetails?.total_feature_meeting_count
                      }}</span
                    >
                    <strong
                      >{{
                        getMeetingPercentage() ? getMeetingPercentage() : 0
                      }}%</strong
                    >
                  </div>
                </div>
                <div
                  *ngIf="featureDifferenceDetails?.total_feature_meeting_count"
                  class="link-outer"
                  (click)="
                    redirectToMeetingLog(
                      dataService?.specCallDetails?.project_id
                    )
                  "
                >
                  <span class="transaction-link"
                    >Transaction logs
                    <em class="icon">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.6667 11.6667H2.33333V2.33333H7V1H2.33333C1.59333 1 1 1.6 1 2.33333V11.6667C1 12.4 1.59333 13 2.33333 13H11.6667C12.4 13 13 12.4 13 11.6667V7H11.6667V11.6667ZM8.33333 1V2.33333H10.7267L4.17333 8.88667L5.11333 9.82667L11.6667 3.27333V5.66667H13V1H8.33333Z"
                          fill="#B388FF"
                        />
                      </svg>
                    </em>
                  </span>
                </div>
              </div>
              <div
                class="feature-info-tooltip"
                *ngIf="!isShowFeatureDifference(featureDifferenceDetails)"
              >
                <div class="info-row">
                  <div class="info-desc">
                    Feature Difference information will be visible once it is
                    available.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="manager_availability_status d-none d-lg-block">
            <div class="header-right-btns">
              <!-- My meetings button -->
              <div
                class="inner-btn"
                [ngClass]="{ active: myMeetingsSidebar }"
                (click)="openMyMeeting()"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.62665 5.625C1.62665 4.79657 2.29822 4.125 3.12665 4.125H18.8766C19.7051 4.125 20.3766 4.79657 20.3766 5.625V18.875C20.3766 19.7034 19.7051 20.375 18.8766 20.375H3.12665C2.29822 20.375 1.62665 19.7034 1.62665 18.875V5.625Z"
                    class="stroke"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.62665 9.125H20.3766"
                    class="stroke"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.62665 6V1.625"
                    class="stroke"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.3766 6V1.625"
                    class="stroke"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="btn-tooltip">My meetings</div>
              </div>
              <!-- My tasks button -->
              <div
                class="inner-btn"
                [ngClass]="{ active: myTaskShow }"
                (click)="toggleHeaderTask($event)"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.625 3.12415C1.625 2.29572 2.29657 1.62415 3.125 1.62415H18.875C19.7034 1.62415 20.375 2.29572 20.375 3.12415V18.8741C20.375 19.7026 19.7034 20.3741 18.875 20.3741H3.125C2.29657 20.3741 1.625 19.7026 1.625 18.8741V3.12415Z"
                    class="stroke"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 4.74915L7.25 9.74915L4.75 7.24915"
                    class="stroke"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.875 7.87415H16.625"
                    class="stroke"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 12.2491L7.25 17.2491L4.75 14.7491"
                    class="stroke"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.875 15.3741H16.625"
                    class="stroke"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="btn-tooltip">My tasks</div>
              </div>
            </div>
          </div>
          <div class="member-profile">
            <div class="profileBlock" (click)="toggleHeaderDropDown($event)">
              <div
                class="initial-circle"
                [ngClass]="{
                  'available-status':
                    commonService.currentStatus === 'Available',
                  'not-available-status':
                    commonService.currentStatus === 'Not Available',
                  'do-not-disturb-status':
                    commonService.currentStatus === 'Do Not Disturb'
                }"
              >
                <span>{{ userData?.first_name?.charAt(0) | uppercase }}</span>
              </div>
              <button
                class="btn btn-secondary profile-arr"
                type="button"
                id="dropdownMenuButton"
                aria-haspopup="true"
                aria-expanded="false"
                aria-label="Menu Dropdown"
              >
                <em class="zmdi zmdi-chevron-down profile-arr-in"></em>
              </button>
            </div>
            <div class="dropdown">
              <div
                class="dropdown-menu"
                [ngClass]="{ show: isShowHeaderDropDown }"
              >
                <div class="dropdownMenuInner">
                  <div class="dropdown-subitem">
                    <div class="subitem-heading">STATUS</div>
                    <div
                      class="item-status-manager"
                      [ngClass]="{
                        'available-status': status === 'Available',
                        'not-available-status': status === 'Not Available',
                        'do-not-disturb-status': status === 'Do Not Disturb'
                      }"
                      *ngFor="let status of statusArray"
                      (click)="statusUpdate(status)"
                    >
                      <div class="status-value">{{ status }}</div>
                      <em
                        class="zmdi zmdi-check"
                        *ngIf="commonService.currentStatus === status"
                      ></em>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div class="dropdown-subitem">
                    <div class="subitem-heading">ACCOUNT</div>
                    <a
                      class="dropdown-item"
                      [routerLink]="['/my-account']"
                      [ngClass]="{
                        onActive: currentSelection === '/my-account'
                      }"
                      ><i class="my-profile" aria-hidden="true"></i>
                      <span>My Account</span></a
                    >
                    <a
                      class="dropdown-item d-lg-none"
                      (click)="toggleHeaderTask($event)"
                      ><i class="my-tasks" aria-hidden="true"></i>
                      <span>My Tasks</span></a
                    >
                    <a
                      class="dropdown-item log-out"
                      (click)="logout()"
                      href="javascript:void(0)"
                    >
                      <i class="power-off" aria-hidden="true"></i>
                      <span>Logout</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-my-task
            *ngIf="myTaskShow"
            (click)="$event.stopPropagation()"
          ></app-my-task>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- <my-meetings-sidebar *ngIf="myMeetingsSidebar" (closeMyMeetings)="myMeetingsSidebar=false"></my-meetings-sidebar> -->
<div [ngClass]="{ 'd-none': !myMeetingsSidebar }">
  <div
    *ngIf="myMeetingsSidebar"
    (click)="myMeetingsSidebar = !myMeetingsSidebar"
    class="my-meeting-backdrop"
  ></div>

  <ng-container #dynamicContainer></ng-container>
</div>

<div
  bsModal
  #SpecStatusModal="bs-modal"
  class="client-modal modal fade"
  id="SpecStatusModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal">
      <div class="modal-body">
        <p>
          Looks like you are already blocked for an instant spec call. Do you
          want to cancel ongoing request?
        </p>
        <p>Let us know why...</p>
        <div class="custom-radio">
          <div
            class="radio radio-primary"
            *ngFor="let reason of reasonArray; let i = index"
          >
            <input
              type="radio"
              id="early_ended"
              name="change_spec_status"
              [checked]="i === selectedIndex"
              id="{{ reason.value }}"
              [value]="reason.value"
              (change)="temp(reason.value, i)"
            />
            <label for="{{ reason.value }}">{{ reason.name }}</label>
          </div>
        </div>
        <div class="form-group d-flex justify-content-end btn-block">
          <input
            class="btn cancel-btn"
            type="reset"
            name=""
            value="Cancel"
            (click)="closeSpecStatusModal()"
          />
          <input
            class="btn add-btn"
            type="submit"
            name=""
            value="Confirm"
            (click)="confirmStatusChange()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
