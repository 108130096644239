<div
  #dropdownRef
  class="custom-dropdown"
  (click)="$event.stopPropagation()"
  *ngIf="
    isShowDropdown &&
    (isSearchLoader || isShowPaginationLoader || mergedMemberList.length > 0)
  "
>
  <ul
    class="drop-down-list"
    #target
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScrollMember()"
    [scrollWindow]="false"
  >
    <ng-container *ngIf="!isSearchLoader">
      <ng-container
        *ngFor="
          let member of mergedMemberList;
          let i = index;
          trackBy: trackByFunc
        "
      >
        <li (click)="selectMember(member)">
          <div class="d-flex flex-wrap align-items-center">
            <span
              class="circle-badge text-uppercase"
              [style.backgroundColor]="
                userInitialColor[i % userInitialColor.length]
              "
              >{{ member.name[0] }}</span
            >
            <span
              title="{{ member.name | titlecase }} <{{ member.email }}>"
              class="circle-info"
            >
              <span class="d-block name">{{ member.name | titlecase }}</span>
              <span class="d-block email">{{ member.email }}</span>
            </span>
          </div>
        </li>
      </ng-container>
    </ng-container>
    <div
      class="pagination-loader load-more-pages"
      *ngIf="isShowPaginationLoader || isSearchLoader"
    >
      <img
        [style.height]="isSearchLoader ? '60px' : 'auto'"
        src="assets/images/pagination-loader.gif"
        alt=""
      />
    </div>
  </ul>
</div>
