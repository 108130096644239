import { Pipe, PipeTransform } from '@angular/core';

/**
 * Custom Angular Pipe that applies a given function with specified arguments.
 *
 * This pipe allows you to dynamically invoke a function within Angular templates.
 *
 * Usage:
 * In a template, you can use this pipe to pass a function and its arguments:
 *
 * {{ value | applyFunc: myFunction: arg1: arg2: ... }}
 *
 * Where `myFunction` is the function to be applied, and `arg1`, `arg2`, etc. are
 * the arguments to be passed to that function.
 *
 * Features:
 * - **Pure Pipe**: This pipe is pure, meaning it will only re-evaluate when the reference
 *   to the input function changes or when the reference of the arguments array changes.
 *
 * - **Standalone Pipe**: This pipe can be used independently in your Angular applications
 *   without needing to be declared in a module.
 *
 * Error Handling:
 * - Throws an error if the first argument is not a function.
 *
 * @example
 * // In a component template:
 * {{ someValue | applyFunc: myFunction: arg1: arg2 }}
 *
 * always pass pure functions as arguments to this pipe
 */
@Pipe({
  name: 'applyFunc',
  pure: true,
  standalone: true
})
export class ApplyFuncPipe implements PipeTransform {
  transform(func: unknown, ...args: unknown[]): unknown {
    if (typeof func !== 'function') {
      throw new Error('Provided argument must be a function');
    }
    return func.bind(null)(...args);
  }
}
