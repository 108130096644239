import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ApiRequests } from '../../shared-module/services/apiRequests.service';
import { CommonService } from '../../shared-module/services/common.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit, OnDestroy, OnChanges {
  memberList = [];
  isShowPaginationLoader = false;
  isSearchLoader = false;
  page: number;
  itemPerPage: number;
  clientList = [];
  filteredClientList = [];
  managerSubscription;
  currentTimeOut;
  userInitialColor = ['#7C4DFF', '#FFAB00', '#00C853', '#FF5546'];

  @Input() participantList = [];
  @Input() searchText = ' ';
  @Output() selectedMember = new EventEmitter<string>();
  @ViewChild('dropdownRef', { static: false }) dropdownRef: ElementRef;
  isShowDropdown = true;
  mergedMemberList = [];

  constructor(
    public apiRequestService: ApiRequests,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.clientList = [...this.participantList];
    if (this.commonService.notifyProjectClientSubject.getValue()) {
      const data = this.commonService.notifyProjectClientSubject.getValue();
      if (data && data.client && Array.isArray(data.associates)) {
        const customerList = data.associates
          .filter(
            (x) =>
              x.email.split('@')[1].toLowerCase() !== 'builder.ai' &&
              x.email.split('@')[1].toLowerCase() !== 'engineer.ai'
          )
          .map((y) => y.email);
        if (
          data.client.email.split('@')[1].toLowerCase() !== 'builder.ai' &&
          data.client.email.split('@')[1].toLowerCase() !== 'engineer.ai'
        ) {
          customerList.push(data.client.email);
        }

        this.clientList = [...customerList.map((x) => ({ name: x, email: x }))];
        this.filteredClientList = this.clientList.filter((x) =>
          x.name.toLowerCase().includes(this.searchText.toLowerCase()?.trim())
        );
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.searchText &&
      this.searchText?.trim() &&
      changes.searchText.previousValue !== changes.searchText.currentValue
    ) {
      this.isShowDropdown = true;
      if (this.currentTimeOut) {
        clearTimeout(this.currentTimeOut);
      }
      this.currentTimeOut = setTimeout(() => {
        this.filteredClientList = this.clientList.filter((x) =>
          x.name.toLowerCase().includes(this.searchText.toLowerCase()?.trim())
        );
        this.getManagers();
      }, 300);
    }
  }

  getManagers(response?) {
    if (response !== 'scroll') {
      this.page = 1;
      this.isShowPaginationLoader = false;
      this.isSearchLoader = true;
    } else {
      this.isShowPaginationLoader = true;
    }
    this.itemPerPage = 16;
    if (this.managerSubscription) {
      this.managerSubscription.unsubscribe();
    }
    this.managerSubscription = this.apiRequestService
      .getManagers(
        this.page,
        this.itemPerPage,
        this.searchText,
        'unarchived',
        true
      )
      .subscribe(
        (data) => {
          this.isSearchLoader = false;
          if (response === 'scroll') {
            this.memberList = this.memberList.concat(data.users);
          } else {
            this.memberList = data.users;
          }
          this.mergedMemberList = [
            ...this.filteredClientList,
            ...this.memberList
          ].filter(
            (item, index, items) =>
              items.findIndex((x) => item.email === x.email) === index
          );

          this.page = data.next_page;
          this.isShowPaginationLoader = false;
        },
        () => {
          this.isSearchLoader = false;
          this.isShowPaginationLoader = false;
        }
      );
  }

  onScrollMember() {
    if (this.page !== null && !this.isShowPaginationLoader) {
      this.getManagers('scroll');
      this.isShowPaginationLoader = true;
    }
  }

  selectMember(member) {
    this.selectedMember.emit(member);
  }

  trackByMsgIdFunc(index, Obj) {
    return Obj.email;
  }

  @HostListener('document:click', ['$event'])
  public documentClickListner($event) {
    if (
      this.dropdownRef &&
      !this.dropdownRef.nativeElement.contains($event.target)
    ) {
      {
        this.isShowDropdown = false;
      }
    }
  }

  ngOnDestroy() {
    if (this.managerSubscription) {
      this.managerSubscription.unsubscribe();
    }

    if (this.currentTimeOut) {
      clearTimeout(this.currentTimeOut);
    }
  }
}
